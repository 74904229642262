import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { containsNode, isNode } from 'utils/DOMUtil';

import GlobalPortal from 'components/commons/GlobalPortal';
import { SELECTBOX_OPTION_LABEL_CLASSNAME } from 'components/commons/SelectBox/consts';
import { SelectBoxContext } from 'components/commons/SelectBox/SelectBoxContext';
import Tooltip from 'components/commons/Tooltip/GlobalTooltip';
import { TooltipOption } from '../Tooltip/GlobalTooltip/types';
import classNames from 'classnames';
import { defaultTheme } from 'theme';
import { isScrollOnBottom } from './utils';
import styled from 'styled-components';
import { useDebounceCallback } from 'components/hooks/useDebounceCallback';

type DropdownRect = {
  top?: string;
  left: string;
  bottom?: string;
  width: string;
};

export type SelectBoxDropdownStyle = BoxStyle;

type SelectBoxDropdownContainerProps = {
  marginTop?: string;
  width?: string;
  border?: string;
  borderRadius?: string;
  boxShadow?: string;
  maxHeight?: string;
  show: boolean;
  dropdownRect?: DropdownRect;
} & Styleable<SelectBoxDropdownStyle>;

type SelectBoxDropdownProps = Omit<SelectBoxDropdownContainerProps, 'show'>;

type SelectBoxDropdownSplitterProps = {
  padding?: string;
  margin?: string;
  fontColor?: string;
};

export const DROPDOWN_CONTAINER_BASE_CLASSNAME = 'spendit-dropdown-container';

export const SelectBoxDropdownItem = styled.li``;

export const SelectBoxDropdownSplitter = styled(SelectBoxDropdownItem)<SelectBoxDropdownSplitterProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${props => props.padding || '0 7px'};
  margin: ${props => props.margin || 'auto'};
  height: 24px;

  background-color: #f5f5f5;
  color: ${props => props.fontColor || '#000'};

  font-size: 10px;

  & button {
    border: 0;
    background-color: transparent;
    padding: 0;
    color: ${defaultTheme.colors.blue[500]};
    font-weight: 500;
    font-size: 10px;
  }
`;

const SelectBoxDropdownContainer = styled.ul<SelectBoxDropdownContainerProps>`
  position: fixed;
  top: ${props => props.dropdownRect?.top || 'auto'};
  left: ${props => props.dropdownRect?.left || 'auto'};
  bottom: ${props => props.dropdownRect?.bottom || 'auto'};
  ${props => props.styles?.transform && `transform: ${props.styles.transform};`}
  visibility:${props => (props.show ? 'visible' : 'hidden')};
  width: ${props => props.width ?? props.dropdownRect?.width ?? '100%'};
  border: ${props => props.border || '1px solid #d4d4d4'};
  border-radius: ${props => props.borderRadius || '5px'};
  box-sizing: border-box;
  box-shadow: ${props => props.boxShadow || '0 1px 4px 0 rgba(0, 0, 0, 0.1)'};
  z-index: 10;

  background-color: #fff;
  ${props =>
    props.maxHeight &&
    `
    max-height: ${props.maxHeight};
    overflow-y: auto;
    overflow-x: hidden; 
  `}

  &::-webkit-scrollbar {
    display: none;
  }

  & > li > ul {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: ${props => props.maxHeight ?? '250px'};
  }
`;

const getDropdownDirection = (targetBottom: number, dropdownHeight: number) => {
  const screenBottom = window.innerHeight;
  const dropdownBottomPosition = targetBottom + dropdownHeight;

  return dropdownBottomPosition > screenBottom ? 'top' : 'bottom';
};

const getDropdownRect = (
  targetElement: HTMLDivElement,
  dropdownElement: HTMLUListElement,
  styles?: BoxStyle
): DropdownRect => {
  const targetRect = targetElement.getBoundingClientRect();
  const dropdownHeight = dropdownElement.clientHeight;
  const { top, left, bottom, width } = targetRect;
  const direction = getDropdownDirection(bottom, dropdownHeight);

  const screenBottom = window.innerHeight;
  const leftFromStyles = Number((styles?.left ?? '0').split('px')[0]);
  const nextLeft = left + leftFromStyles;

  if (direction === 'bottom') {
    return {
      top: `${bottom + 4}px`,
      left: `${nextLeft}px`,
      width: `${width}px`,
    };
  }
  return {
    bottom: `${screenBottom - top + 4}px`,
    left: `${nextLeft}px`,
    width: `${width}px`,
  };
};

export const SelectBoxDropdown: React.FC<React.PropsWithChildren<SelectBoxDropdownProps>> = ({
  className,
  children,
  styles,
  ...rest
}) => {
  const { open, onScrollBottomReached, selectBoxContainerElRef, closeDropdown, toggleElementResized, values } =
    useContext(SelectBoxContext);
  const [dropdownPosition, setDropdownPosition] = useState<DropdownRect>();
  const [tooltipOptions, setTooltipOptions] = useState<{
    options?: TooltipOption;
    contents?: string;
  }>();
  const dropdownContainerElRef = useRef<HTMLUListElement>(null);
  const [height, setHeight] = useState<number | null>(null);
  const tooltipTargetElRef = useRef<HTMLElement | null>(null);
  const { debouncedCallback: debouncedOnScrollBottomReached } = useDebounceCallback(onScrollBottomReached);

  const measuredRef = useCallback((el: HTMLUListElement | null) => {
    if (el === null) {
      setHeight(null);
      return;
    }
    setHeight(el.getBoundingClientRect().height);
  }, []);

  const clearTooltip = useCallback(() => {
    if (tooltipTargetElRef.current) {
      tooltipTargetElRef.current.removeEventListener('mouseleave', clearTooltip);
      tooltipTargetElRef.current = null;
    }
    setTooltipOptions(undefined);
  }, []);

  const handleScroll = () => {
    if (isScrollOnBottom(dropdownContainerElRef.current)) {
      debouncedOnScrollBottomReached();
    }
  };

  const handleMouseOver = (event: React.MouseEvent<HTMLUListElement>) => {
    event.stopPropagation();
    const { target: eventTarget } = event;

    if (
      eventTarget instanceof HTMLElement &&
      typeof eventTarget?.className === 'string' &&
      eventTarget.className.indexOf(SELECTBOX_OPTION_LABEL_CLASSNAME) > -1
    ) {
      const { scrollWidth, clientWidth } = eventTarget;

      if (scrollWidth > clientWidth) {
        tooltipTargetElRef.current = eventTarget;
        tooltipTargetElRef.current.addEventListener('mouseleave', clearTooltip);

        setTooltipOptions({
          options: {
            direction: 'top',
            target: eventTarget,
          },
          contents: eventTarget.textContent ?? '',
        });
      }
    }
  };

  useEffect(() => {
    if (!dropdownPosition) {
      clearTooltip();
    }
  }, [dropdownPosition, clearTooltip]);

  useEffect(() => {
    function handleWindowScroll(event: Event) {
      const eventTarget = event.target;
      const isFromDropdown = isNode(eventTarget) && containsNode(dropdownContainerElRef.current, eventTarget);
      if (!isFromDropdown && closeDropdown) {
        closeDropdown();
      }
    }

    if (open) {
      window.addEventListener('scroll', handleWindowScroll, true);
    } else {
      window.removeEventListener('scroll', handleWindowScroll, true);
    }

    return () => window.removeEventListener('scroll', handleWindowScroll, true);
  }, [open, closeDropdown]);

  const recalculateRect = useCallback(() => {
    if (selectBoxContainerElRef?.current && dropdownContainerElRef?.current) {
      const nextRect = getDropdownRect(selectBoxContainerElRef.current, dropdownContainerElRef.current, styles);

      if (dropdownContainerElRef.current) {
        if (nextRect.top) {
          dropdownContainerElRef.current.setAttribute(
            'style',
            `top: ${nextRect.top};bottom: auto;left: ${nextRect.left}`
          );
        } else if (nextRect.bottom) {
          dropdownContainerElRef.current.setAttribute(
            'style',
            `top: auto;bottom: ${nextRect.bottom};left: ${nextRect.left}`
          );
        }
      }
    }
  }, [styles]);

  useEffect(() => {
    if (open && selectBoxContainerElRef?.current && dropdownContainerElRef?.current) {
      setDropdownPosition(getDropdownRect(selectBoxContainerElRef.current, dropdownContainerElRef.current, styles));
      window.addEventListener('resize', recalculateRect);
    } else {
      setDropdownPosition(undefined);
      window.removeEventListener('resize', recalculateRect);
    }

    return () => {
      window.removeEventListener('resize', recalculateRect);
    };
  }, [styles, open, recalculateRect]);

  useEffect(() => {
    if (values.length > 0 && toggleElementResized) {
      recalculateRect();
    }
  }, [values, toggleElementResized, recalculateRect]);

  useEffect(() => {
    if (height === null || dropdownContainerElRef.current === null) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      recalculateRect();
    });

    resizeObserver.observe(dropdownContainerElRef.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [recalculateRect, height]);

  return (
    <GlobalPortal show={open}>
      <SelectBoxDropdownContainer
        ref={el => {
          dropdownContainerElRef.current = el;
          measuredRef(el);
        }}
        className={classNames(DROPDOWN_CONTAINER_BASE_CLASSNAME, className)}
        onScroll={handleScroll}
        onMouseOver={handleMouseOver}
        dropdownRect={dropdownPosition}
        show={!!dropdownPosition}
        styles={styles}
        {...rest}
      >
        {children}
      </SelectBoxDropdownContainer>
      {tooltipOptions?.contents && <Tooltip option={tooltipOptions.options}>{tooltipOptions.contents}</Tooltip>}
    </GlobalPortal>
  );
};
