import { Accordion } from '@mantine/core';
import { ExpandDown } from '@N/view/assets/new_icons/ExpandDown';

export const AccordionResolver = Accordion.extend({
  defaultProps: {
    chevron: <ExpandDown />,
  },
  styles: theme => ({
    control: {
      padding: 0,
      boxSizing: 'border-box',
    },
    content: {
      padding: 0,
    },

    icon: {
      width: 12,
      height: 12,
    },
    item: {
      borderBottom: 'none',
    },
    chevron: {
      path: {
        stroke: theme.colors.gray[6],
      },
    },
  }),
});
