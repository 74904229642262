import { Colors } from 'theme/colors';
import { MantineColorsTuple } from '@mantine/core/lib/core/MantineProvider/theme.types';

export const isValidAlpha = (alpha: number): boolean => alpha >= 0 && alpha <= 1;

// TODO: 레거시 colors를 쓰는 함수 제거
export const splitHexToRgb = (hexColor: Colors): { R: number; G: number; B: number } => {
  const R = parseInt(hexColor.slice(1, 3), 16);
  const G = parseInt(hexColor.slice(3, 5), 16);
  const B = parseInt(hexColor.slice(5, 7), 16);

  return { R, G, B };
};

// TODO: 레거시 colors를 쓰는 함수 제거
export const createRGBAWithHex = (hexColor: Colors, alpha = 1) => {
  const { R, G, B } = splitHexToRgb(hexColor);
  return `rgba(${R},${G},${B},${isValidAlpha(alpha) ? alpha : 1})`;
};

// TODO: splitHexToRgb_Temp -> splitHexToRgb로 변경
export const splitHexToRgb_Temp = (hexColor: MantineColorsTuple[number]): { R: number; G: number; B: number } => {
  const R = parseInt(hexColor.slice(1, 3), 16);
  const G = parseInt(hexColor.slice(3, 5), 16);
  const B = parseInt(hexColor.slice(5, 7), 16);

  return { R, G, B };
};

// TODO: createRGBAWithHex_Temp -> createRGBAWithHex로 변경, defaultTheme에 추가
export const createRGBAWithHex_Temp = (hexColor: MantineColorsTuple[number], alpha = 1) => {
  const { R, G, B } = splitHexToRgb_Temp(hexColor);
  return `rgba(${R},${G},${B},${isValidAlpha(alpha) ? alpha : 1})`;
};
