import styled, { css } from 'styled-components';

import { ColorKeysWithoutGrey } from 'theme/colors';

type ButtonSize = 'small' | 'medium' | 'large';
export type ButtonVariant = 'fill' | 'outline' | 'text' | 'underline';

export type ButtonStyleProps = {
  variant: ButtonVariant;
  colorScheme: ColorKeysWithoutGrey;
  size: ButtonSize;
  isLoading: boolean;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
};

const small = css`
  font-size: 12px;
  line-height: 12px;
  padding: 6px 10px;
  border-radius: 2px;
  height: 29px;
`;
const medium = css`
  font-size: 14px;
  line-height: 12px;
  padding: 10px 14px;
  border-radius: 3px;
`;
const large = css`
  font-size: 18px;
  line-height: 16px;
  padding: 12px 72px;
  border-radius: 5px;
`;

const baseButtonStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-weight: bold;
  box-sizing: border-box;
  white-space: nowrap;
`;

export const getButtonSize = (size: ButtonSize) => {
  switch (size) {
    case 'small':
      return small;
    case 'medium':
      return medium;
    case 'large':
      return large;
  }
};

export const getButtonColors = ({ colorScheme, isLoading, variant }: Omit<ButtonStyleProps, 'size'>) => {
  return css`
    ${({ theme }) => {
      //normal
      const color = theme.colors[colorScheme];
      return `
        background-color: ${variant === 'fill' ? color[500] : theme.colors.grey[0]};
        color: ${variant === 'fill' ? theme.colors.grey[0] : color[500]};
        border: ${variant === 'text' || variant === 'underline' ? '1px solid transparent' : `1px solid ${color[500]}`};
      `;
    }}
    &:hover:not(:disabled) {
      ${({ theme }) => {
        const color = theme.colors[colorScheme];
        return `
          box-shadow: 0 0 4px 0 ${color[500]}66;        
        `;
      }}
    }

    &:focus:not(:disabled) {
      outline: 1px solid ${({ theme }) => theme.colors.blue[200]};
    }

    &:disabled {
      cursor: not-allowed;
      ${({ theme }) => {
        const grey = theme.colors.grey;
        return `
          color: ${variant === 'fill' ? grey[0] : grey[300]};
          background-color: ${variant === 'fill' ? grey[300] : grey[0]};
          border: 1px solid ${grey[300]};
        `;
      }}
    }

    &:active:not(:disabled) {
      ${({ theme }) => {
        const color = theme.colors[colorScheme];
        return `
          box-shadow: 0 0 4px 0 ${color[500]}66;
          color: ${variant === 'fill' ? theme.colors.grey[0] : color[500]};
          background-color: ${variant === 'fill' ? color[800] : color[100]};
          border: 1px solid ${variant === 'fill' ? 'transparent' : color[500]};
        `;
      }}
    }

    ${({ theme }) => {
      // 로딩 시 스타일링 적용
      const color = theme.colors[colorScheme];
      if (isLoading) {
        return `
          background-color:${variant === 'fill' ? color[300] : color[50]} !important;
          color: ${variant === 'fill' ? theme.colors.grey[0] : color[500]} !important;
          border: 1px solid ${variant === 'fill' || variant === 'underline' ? 'transparent' : color[500]} !important;
        `;
      }
    }}

    ${() => {
      if (variant === 'underline') {
        return `
          padding: 0;
          height: fit-content;
          & > div {
            text-decoration: underline;
          }
          &:hover:not(:disabled), &:active:not(:disabled) {
            box-shadow: none;
            background-color: transparent;
            border: 1px solid transparent;
          }
        `;
      }
    }}
  `;
};

export const ButtonRoot = styled.button<ButtonStyleProps>`
  ${baseButtonStyle};
  ${({ size }) => getButtonSize(size)};
  ${({ variant, isLoading, colorScheme }) => getButtonColors({ colorScheme, isLoading, variant })}
`;

export const ContentWrapper = styled.div<Pick<ButtonStyleProps, 'isLoading'>>`
  visibility: ${({ isLoading }) => (isLoading ? 'hidden' : 'visible')};
  display: flex;
  align-items: center;
`;

export const LeftIconWrapper = styled.div<{ size: ButtonSize }>`
  margin-right: ${({ size }) => (size === 'small' ? '3px' : '6px')};
  display: inline-flex;
  align-items: center;
`;

export const RightIconWrapper = styled.div<{ size: ButtonSize }>`
  margin-left: ${({ size }) => (size === 'small' ? '3px' : '6px')};
  display: inline-flex;
  align-items: center;
`;
