import { IconComponentBaseProps } from 'components/atoms/Icon/types';

type ExpandDownProps = IconComponentBaseProps;

export const ExpandDown = ({ width = 12, height = 12, fill = 'currentColor', className }: ExpandDownProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.7734 11.8874L30.2279 9.34178L18.0006 21.569L5.77344 9.34178L3.22785 11.8874L18.0006 26.6602L32.7734 11.8874Z"
        fill={fill}
      />
    </svg>
  );
};
